<template>
    <div class="subject">
        <b-card no-body class="mb-1">
            <b-card-header class="pb-0">
                <strong class="mr-auto">فلترة حسب</strong>
                <b-button type="submit" class="mr-1" variant="primary" @click="
                    getSubjectsList({ ...filterDto, search: search.search })
                    ">فلترة
                </b-button>
                <b-button variant="secondary" @click="resetFilter">تهيئة</b-button>
            </b-card-header>
            <b-card-body class="pb-50">
                <b-row>
                    <b-col cols="12" md="3">
                        <ek-input-select label="جامعة" placeholder=" اختر جامعة" :options="[
                            { id: '', name: 'الكل' },
                            ...universityList,
                        ]" v-model="filterDto.universityId" name="select" :clearable="true" />
                    </b-col>
                    <b-col cols="12" md="3">
                        <ek-input-select label="الكلية" placeholder="اختر الكلية " :options="filteredFaculties"
                            name="select" v-model="filterDto.facultyId" :clearable="true" />
                    </b-col>
                    <b-col cols="12" md="3">
                        <ek-input-select label="السنة" placeholder="اختر السنة "
                            :options="[{ id: '', name: 'الكل' }, ...years]" name="select" v-model="filterDto.yearId"
                            :clearable="true" />
                    </b-col>
                    <b-col cols="12" md="3">
                        <ek-input-select label="الفصل" placeholder="اختر الفصل "
                            :options="[{ id: '', name: 'الكل' }, ...semesters]" name="select" v-model="filterDto.semesterId"
                            :clearable="true" />
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <b-row class="subjects">
            <b-col lg="3" md="6" v-for="subject in subjectList.subjects" :key="subject.id">
                <b-card id="my-card">
                    <template #header class="p-0">
                        <img :style="!subject.url ? 'object-fit: contain' : ''" :src="subject.url ? $store.getters['app/domainHost'] +
                            '/' +
                            subject.url: appName" alt="" srcset="" />
                    </template>
                    <b-card-body body-class="p-0">
                        <div class="mt-1">
                            <span class="w-100 d-flex justify-content-center align-items-center">
                                <unicon name="folder" class="pl-1"></unicon>
                                <span class="px-1">
                                    ({{ subject.coursesCount }})
                                </span>
                            </span>
                            <h3 class="mt-1">{{ subject.name }}</h3>
                            <p>
                                {{
                                    semesters.find(
                                        (sem) => sem.id == subject.semesterId
                                    ).name
                                }}
                                -
                                {{
                                    years.find((y) => y.id == subject.yearId)
                                        .name
                                }}
                            </p>
                            <p>
                                {{
                                    facultyList.find(
                                        (fac) => fac.id == subject.facultyId
                                    ).name
                                }}
                                -
                                {{
                                    branches.find(
                                        (br) => br.id == subject.departmentId
                                    ).name
                                }}
                            </p>
                            <p>
                                {{
                                    universityList.find(
                                        (un) => un.id == subject.universityId
                                    ).name
                                }}
                            </p>
                        </div>
                    </b-card-body>

                    <template #footer>
                        <b-button variant="main-color" class="w-100 p-1" @click="goToDetails(subject.id)">عرض التفاصيل
                        </b-button>
                    </template>
                </b-card>
            </b-col>
            <div class="d-flex justify-content-center align-items-center flex-column w-100">
                <b-pagination v-model="filterDto.pageIndex" pills :total-rows="subjectList.count"
                    :per-page="filterDto.pageSize" aria-controls="my-card" @change="paginate">
                </b-pagination>
            </div>
        </b-row>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { $themeConfig } from '@themeConfig'

export default {
    data() {
        return {
            appName: $themeConfig.app.appName,
            filterDto: {
                semesterId: "",
                yearId: "",
                facultyId: "",
                universityId: "",
                pageSize: 4,
                pageIndex: 1,

            },
        };
    },

    computed: {
        ...mapState({
            subjectList: (state) => state.subjects.subjects,
            universityList: (state) => state.university.universityList,
            facultyList: (state) => state.faculty.facultyList,
            branches: (state) => state.branches.branchList,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
            search: (state) => state.subjects.filterDto,
        }),

        ...mapGetters(["subjectsList"]),


        filteredFaculties() {
            // Filter the facultyList based on the selected university
            if (this.filterDto.universityId) {
                return [
                    { id: '', name: 'الكل' },
                    ...this.facultyList.filter(
                        (faculty) => faculty.universityId === this.filterDto.universityId
                    ),
                ];
            } else {
                // If no university is selected, show all faculties
                return [
                    { id: '', name: 'الكل' },
                    ...this.facultyList,
                ];
            }
        },



        // filteredYears() {

        //   if (this.filterDto.facultyId) {
        //     return [
        //       { id: '', name: 'الكل' },
        //       ...this.years.filter(
        //         (year) => year.facultyId === this.filterDto.facultyId
        //       ),
        //     ];
        //   } else {

        //     return [
        //       { id: '', name: 'الكل' },
        //       ...this.years,
        //     ];
        //   }

        // },

    },

    methods: {
        paginate(e) {
            console.log(e);
            this.getSubjectsList({
                ...this.filterDto,
                pageIndex: e,
            });
        },
        goToDetails(id) {
            this.$router.push({ path: `/admin/subjects/${id}` });
        },
        ...mapActions([
            "getSubjectsList",
            "getFacultyList",
            "getUniversityList",
            "getSettingYear",
            "getSettingSemester",
        ]),

        resetFilter() {
            Object.assign(this.filterDto, {
                semesterId: "",
                yearId: "",
                facultyId: "",
                universityId: "",
            });
            this.getSubjectsList(this.filterDto);
        },
    },
    created() {
        this.getSubjectsList(this.filterDto);
        this.getFacultyList();
        this.getUniversityList();
        this.getSettingYear();
        this.getSettingSemester();
    },

    // beforeDestroy() {
    //     this.$store.commit("Set_Subjects_Dto");
    // },
    //     items() {
    //                 this.universityList.find(
    //                   (el) => el.id ===this. filterDto.facultyId
    //                 )?.faculty

    // }
}

</script>

<style lang="scss">
@import "/src/@core/scss/core.scss";

.subject {
    p {
        margin: 5px;
    }

    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 10px;
    }

    .unicon.button {
        svg {
            fill: $main-color !important;
        }
    }

    svg {
        fill: #6e6b7b;
    }

    .subjects {
        .card-body {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        .card-header {
            padding: 0 !important;
        }

        .card-footer {
            padding: 0 !important;
            text-align: center;
            cursor: pointer;

            button {
                color: #fff !important;
                border-top-left-radius: 0 !important;
                border-top-right-radius: 0 !important;
            }
        }
    }
}
</style>
